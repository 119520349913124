import React, { useState, useEffect } from "react";
import currency from "currency.js";
import { Spinner } from "spin.js";
import Toastify from "toastify-js";

import PricePointSelect from "./price_point_select.js";

// process.env.STRIPE_PUBLISHABLE
const stripe = Stripe(process.env.STRIPE_PUBLISHABLE);
let elements = stripe.elements();

// Create an instance of the card UI component
let card = elements.create("card", {
  style: {
    base: {
      fontFamily: "Arial, sans-serif",
      fontSize: "30px",
      color: "#C1C7CD",
    },
    invalid: {
      color: "red",
    },
  },
});

// LoadingPanel component for displaying a loading spinner
const LoadingPanel = ({ loading_payment }) => {
  if (!loading_payment) {
    return null;
  }

  return (
    <div className="loading-panel">
      <h3 className="lp-heading">Sending Payment information</h3>
      <div id="loading-spinner"></div>
      <br />
      <p className="lp-content">Please do not reload the page.</p>
    </div>
  );
};

const DirectPaymentForm = (props) => {
  let altButtonStyles = {
    color: props.buttonStyles.backgroundColor,
    backgroundColor: props.buttonStyles.color,
    border: `2px solid ${props.buttonStyles.backgroundColor}`,
  };

  const [state, setState] = useState({
    current_camper: "",
    liability_waiver: false,
    shirt_size: "",
    custom_data: {},
    graduation_year: "",
    discount_code: "",
    button_available: false,
    discounted: false,
    loading_payment: false,
    price_point_id: props.price_point_id,
    price_points: props.price_points,
    camper_id: props.camper_id,
    camp_id: props.camper_id,
    registration_id: props.registration_id,
    alt_button_styles: altButtonStyles,
  });

  // Additional helper functions and event handlers here

  useEffect(() => {
    card.mount("#card-element");

    card.addEventListener("change", function (event) {
      let displayError = document.getElementById("card-errors");
      let submitButton = document.getElementById("sub-btn");
      if (event.complete) {
        submitButton.removeAttribute("disabled");
      } else if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    let pricePoints = JSON.parse(props.price_points);
    let pricePoint = pricePoints.find(
      (obj) => obj.id == props.price_point_id
    );
    setCartFields(pricePoint, state.discounted);

    // Clean up
    return () => {
      card.unmount();
      card.destroy();
    };
  }, []);

  useEffect(() => {
    if (validDiscountCode()) {
      let pricePoints = JSON.parse(props.price_points);
      let pricePoint = pricePoints.find((obj) => obj.id === state.price_point_id);
      setCartFields(pricePoint, true);
    }
  }, [state.discount_amount]);

  useEffect(() => {
    let target = document.getElementById("loading-spinner");
    if (target != null) {
      new Spinner({ color: "#fff", lines: 10, speed: 1 }).spin(target);
    }
  });

  const valid = () => {
    return state.button_available;
  };

  const validateDiscountCodeLength = (field) => {
    if (field.length > 5) {
      return true;
    }
  };

  const validDiscountCode = () => {
    return validateDiscountCodeLength(state.discount_code);
  };

  const resetDiscountCode = () => {
    Toastify({
      text: "Discount Removed",
      duration: 3000,
      close: false,
      position: "right", // `left`, `center` or `right`
      backgroundColor: "#dc3545",
      className: "discount-failure",
      stopOnFocus: true, // Prevents dismissing of toast on hover
    }).showToast();
    let discountRow = document.getElementById("discount-items");
    let discountTag = document.getElementById("discount-tag");
    let cancelDiscount = document.getElementById("remove-discount-code");

    cancelDiscount.classList.add("hidden");
    discountTag.classList.add("hidden");
    discountRow.classList.add("hidden");

    let pricePoints = JSON.parse(props.price_points);
    let pricePoint = pricePoints.find(
      (obj) => obj.id == state.price_point_id
    );
    setCartFields(pricePoint, false);
    setState((prevState) => ({ ...prevState, discounted: false, discount_code: "" }));
  };

  const checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showToast = (text, isSuccess) => {
    Toastify({
      text,
      duration: 3000,
      close: false,
      position: "right",
      backgroundColor: isSuccess ? "#3ecf8e" : "#dc3545",
      className: isSuccess ? "discount-success" : "discount-failure",
      stopOnFocus: true,
    }).showToast();
  };

  const calculatePercentageDiscount = (subTotalCents, discountCents) => {
    let percentOff = discountCents.divide(100);

    if (percentOff > 1) {
      showToast("Discount Invalid - cannot apply discount for more than 100%", false);
      return currency(0, { fromCents: true });
    }

    showToast("Discount Successful!", true);
    return subTotalCents.multiply(percentOff);
  };

  const calculateFixedDiscount = (subTotalCents, discountCents) => {
    if (discountCents.dollars() > subTotalCents.dollars()) {
      showToast("Discount Invalid - cannot apply discount for more than price", false);
      return currency(0, { fromCents: true });
    }

    showToast("Discount Successful!", true);
    return discountCents;
  };

  const setCartFields = (pricePoint, discounted) => {
    let subTotalCents = currency(pricePoint.price_cents, { fromCents: true });
    let discountCents = currency(state.discount_amount, { fromCents: true });
    let amountOff = currency(0, { fromCents: true });

    if (discounted) {
      if (state.discount_type === "percentage") {
        amountOff = calculatePercentageDiscount(subTotalCents, discountCents);
      } else {
        amountOff = calculateFixedDiscount(subTotalCents, discountCents);
      }

      document.getElementById("discount").innerText = `- ${amountOff.format()}`;
      document.getElementById("discount-tag").innerText = state.discount_code;
      document.getElementById("discount-items").classList.remove("hidden");
      document.getElementById("remove-discount-code").classList.remove("hidden");
    }

    subTotalCents = subTotalCents.subtract(amountOff);

    if (props.fee_breakout) {
      let firewoodFeeCents = subTotalCents.multiply(props.fee_percentage);
      let stripeFixedCents = currency(30, { fromCents: true });
      subTotalCents = subTotalCents.add(firewoodFeeCents).add(stripeFixedCents).divide(0.971);
      document.getElementById("fee").innerText = subTotalCents.subtract(firewoodFeeCents).subtract(stripeFixedCents).format();
    }

    document.getElementById("subtotal").innerText = subTotalCents.add(amountOff).format();
    document.getElementById("total-value").innerText = subTotalCents.format();
  };


  const pricePointDropdownChanged = (newId) => {
    setState((prevState) => ({
      ...prevState,
      price_point_id: newId,
    }));

    let pricePoints = JSON.parse(props.price_points);
    let pricePoint = pricePoints.find((obj) => obj.id == newId);
    setCartFields(pricePoint, state.discounted);
  };

  const handleChange = (e) => {
    const name = e.target.getAttribute("data-name");
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const createToken = () => {
    function stripeTokenHandler(token) {
      let form = document.getElementById("new_registration");
      let hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "payment[stripeToken]");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);
      form.submit();
      setState(prevState => ({ ...prevState, loading_payment: true, disabled: true }));
    }
    stripe.createToken(card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById("card-errors");
        errorElement.textContent = result.error.message;
        setState(prevState => ({ ...prevState, loading_payment: false, disabled: false }));
      } else {
        stripeTokenHandler(result.token);
      }
    });
  };

  const handleDiscountCode = (e) => {
    const discountBox = document.querySelector(".discount-box");
    let code = discountBox ? discountBox.value : '';

    if (state.discounted === false) {
      let discountRow = document.getElementById("discount-items");
      let discount = document.getElementById("discount");

      fetch("/verify_discount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ discount: { camp_id: props.camp_id, code } }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            let discountData = data.discount_data;
            if (discountData) {
              let discountType = discountData.discount_type;
              let discountAmountCents = discountData.amount_cents;
              setState(prevState => ({ ...prevState, discounted: true, discount_amount: discountAmountCents, discount_type: discountType }));
              let pricePoints = JSON.parse(props.price_points);
              let pricePoint = pricePoints.find(
                (obj) => obj.id === state.price_point_id
              );
              setCartFields(pricePoint, true);
            }
          } else {
            setState(prevState => ({ ...prevState, discounted: false, discount_code: "" }));
            discount.classList.add("price-value");
            discountRow.classList.remove("hidden");
            let nonDiscountAmountOff = currency(0, { fromCents: true });
            discount.innerText = "- " + nonDiscountAmountOff.format();

            Toastify({
              text: "Discount Invalid",
              duration: 3000,
              newWindow: false,
              close: false,
              className: "discount-failure",
              gravity: "top", // `top` or `bottom`
              position: "right", // `left`, `center` or `right`
              backgroundColor: "#dc3545",
              stopOnFocus: true,
            }).showToast();
          }
        });
      }
    };



  const handleSubmit = (e) => {
    let form = document.getElementById("new_registration");
    e.preventDefault();
    createToken();
  };


  return (
    <form
      className={"new-step-form"}
      width={"100%"}
      id={"new_registration"}
      method={"POST"}
      action={`/camps/${props.camp_id}/registrations/${props.registration_id}/direct_create`}
    >
      <div className={""}>
        <h1 className={"form-heading"}>{"Finalize Payment"}</h1>
        <div className="purchase-recap">
          <PricePointSelect
            price_points={JSON.parse(props.price_points)}
            primary_price_point={props.price_point}
            onChange={pricePointDropdownChanged}
          />
        </div>
      </div>
      <div className={"payment-column"}>
        <input key={"utf8"} name={"utf8"} type={"hidden"} value={"✓"} />
        <input
          key={"_method"}
          name={"_method"}
          type={"hidden"}
          value={"put"}
        />
        <input
          key={"authenticity_token"}
          name={"authenticity_token"}
          type={"hidden"}
          value={props.crsf_token}
        />
        <input
          key={"registration[camper_id]"}
          name={"registration[camper_id]"}
          type={"hidden"}
          value={props.camper_id}
        />
        <input
          key={"registration[price_point_id]"}
          name={"registration[price_point_id]"}
          type={"hidden"}
          value={props.price_point_id}
        />
      </div>
      <LoadingPanel loading_payment={state.loading_payment} />
      <div className={"camper-info-row"}>
        <div id={"card-element"} className={"full-field"}></div>
        <div id={"card-errors"} role={"alert"}></div>
      </div>
      <div className={"camper-info-row"}>
        <img
          className={"visa-cc"}
          src={
            "https://res.cloudinary.com/buck/image/upload/v1454771871/visa.png"
          }
          alt={"we take visa"}
        />
        <img
          className={"master-cc"}
          src={
            "https://res.cloudinary.com/buck/image/upload/v1454771871/mastercard.png"
          }
          alt={"we take mastercard"}
        />
        <img
          className={"disc-cc"}
          src={
            "https://res.cloudinary.com/buck/image/upload/v1454771870/discover.png"
          }
          alt={"we take discover"}
        />
        <img
          className={"amex-cc"}
          src={
            "https://res.cloudinary.com/buck/image/upload/v1454771871/amex.png"
          }
          alt={"we take amex"}
        />
      </div>
      <div className={"discount-row"}>
        <button
          type={"button"}
          id={"remove-discount-code"}
          className={"remove-discount-code hidden"}
          onClick={resetDiscountCode}
        >
          {"Remove"}
        </button>
        <input
          className={"discount-box half-field"}
          name={"payment[discount_code]"}
          type={"text"}
          value={state.discount_code}
          size={30}
          maxLength={30}
          placeholder={"Enter Discount Code..."}
          data-name={"discount_code"}
          onChange={handleChange}
        />
        <button
          disabled={state.discounted}
          className={"verify-discount-code"}
          style={state.alt_button_styles}
          type={"button"}
          onClick={handleDiscountCode}
        >
          {"Apply"}
        </button>
      </div>
      <div className={"camper-info-row"}>
        <button
          type={"submit"}
          id={"sub-btn"}
          className={"button button-primary create payment-button"}
          disabled={state.disabled}
          style={props.buttonStyles}
          onClick={handleSubmit}
        >
          {"Register"}
        </button>
      </div>
    </form>
  );
}


export default DirectPaymentForm;
